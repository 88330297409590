/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, YouTube } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Videos - Villa Club Tropicana Seychelles"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--60 pt--60" name={"bcv8k28sfmt"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"5l3683acti"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-2);\">Virtual tour through our ViSTA apartment</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"u8MS7Nz-iu8"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2sucuv7cij5"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-2);\">Virtual tour through our lovely and unique GRANiTE apartment</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"vBa-mFdlAwo"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wj5lnyeqlc"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--43" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"C1UoQbD0FBk"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"gzqmmw8pknq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"uFM0l3Qlrf8"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"beekj3794mg"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"ghVinM22brE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"hvn7uiyuyq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video </span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"5UGzIL3DSdo"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"umycutxp6yh"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video&nbsp;</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"H-vB6ogSC0g"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"yolmxmazqoa"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Villa Club Tropicana promo video </span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"kH2VJsY6Gyk"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"2nem64uvwh7"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"backgroundColor":"var(--white)"}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Sunbirds (Colibri) in the garden of Villa Club Tropicana Seychelles</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"YcijTes2MjE"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"oi1x8iajsxg"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Al Fresco dining on the terrace of Villa Club Tropicana</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"aMFZzQ5viC0"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"dveqv7ynpfm"}>
        </Column>


        <Column className="pb--60 pt--60" name={"5lymxmy4yux"}>
        </Column>


        <Column className="pb--60 pt--60" name={"c4duo801r2j"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Sunrise Time-lapse</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"ms-1_-B9hTc"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rlqce5ph4x"}>
        </Column>


        <Column className="pb--60 pt--60" name={"qa3mgtuvio"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">Morning coffee at Villa Club Tropicana Seychelles</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"hyU7vfflne8"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"vvqo40vi5hk"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2);\">The rare Seychelles Kestrel - visiting<br>Villa Club Tropicana Seychelles</span><br>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"lIworr3pVz8"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}